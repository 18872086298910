/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useEffect, useMemo, useContext, Context } from 'react';
import { GoogleMap, useJsApiLoader} from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import * as mapStyle from './mapStyle.json';
import * as mapStyleNoProjects from './mapStyleNoProjects.json';

import styles from './Map.module.scss';
import { ViewContext } from '../../context/ViewProvider';
import TagsOverlay from '../tagsOverlay/tagsOverlay';

interface MapProps {
  children?: (map: google.maps.Map) => JSX.Element;
  context?: Context<any>;
  noProjects?: boolean;
  showTags?: boolean;
}

function Map(props: MapProps) {
  const { context, noProjects, showTags } = props;
  const { t } = useTranslation();
  const viewContext = context ? useContext(context) : useContext(ViewContext);
  const mapLang = useMemo(() => localStorage.getItem('language'), [t('mapLanguage')]);
  const [mapReady, setMapReady] = useState(false);
  const [currentMap, setCurrentMap]: any = useState();
  const [mapViewProps, setMapViewProps] = useState(
    {
      zoom: 3,
      center: {
        lat: 40.7149721,
        lng: -3.5467
      }
    });

  const loader =
    useJsApiLoader({
      googleMapsApiKey: process.env.REACT_APP_API_KEY as string,
      libraries: [],
      version: 'quarterly',
      language: 'es',
    });
  const { isLoaded } = loader;

  useEffect(() => {
    // changed language, so reload map
    setMapReady(false);
    if (currentMap) {
      currentMap.setOptions({
        language: mapLang // Update map language
      });
      setMapReady(true);
    }
  }, [mapLang]);

  useEffect(() => {
    if (viewContext.mainView && currentMap && !(currentMap instanceof google.maps.Map)) {
      setMapReady(false);
      if (currentMap) {
        currentMap.setOptions({
          language: mapLang // Update map language
        });
        setMapReady(true);
      }
    }

  }, [viewContext.mainView]);

  const onLoad = useCallback(
    async function asynccallback(mapInstance: google.maps.Map) {
      console.log('loaded');
      setCurrentMap(mapInstance);
      google.maps.event.addListenerOnce(mapInstance, 'projection_changed', () => {
        setMapReady(true);
      });
    }, []);

  const onUnmountMap = () => {
    if (currentMap) {
      setMapViewProps({
        zoom: currentMap.getZoom(),
        center: currentMap.getCenter()?.toJSON()
      });
    }
  };

  function MapTagsOverlay() {
    return (
      <div className={styles.tagsOverlay}>
        <TagsOverlay />
      </div>
    );
  }

  const renderMap = () => (
    <div data-testid='map-projects' className={`${styles.mapProjects} map-div`} >
      <GoogleMap
        id={`${new Date().getTime()}-map-projects`}
        mapContainerStyle={{
          width: '100%',
          height: '100%',
        }}
        options={{
          minZoom: 2.3,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          styles: noProjects ? mapStyleNoProjects : mapStyle,
          gestureHandling: 'greedy'
        }}
        zoom={screen.width < 1400 ? 2.3 : mapViewProps.zoom}
        center={mapViewProps.center}
        onLoad={onLoad}
        onUnmount={onUnmountMap}
      >
        {showTags && <MapTagsOverlay />}
      </GoogleMap >
      {mapReady && props.children && props.children(currentMap)}
    </div >

  );

  return (
    <div className={styles.containerMap} style={viewContext?.mapIsSelected ? { display: 'flex' } : { display: 'none' }}>
      {isLoaded ? renderMap() : <LoadingSpinner />}
    </div>
  );
}

export default React.memo(Map);
